<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__button-wrapper" v-if="editAccess">
      <rcc-button @click="$router.push({ name: 'process-new' })">
        Создать процесс
      </rcc-button>
    </div>

    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isShowFilters="false"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import headerData from './headers/process-list-header'
import ProcessesApi from '@/api/process-list'

import RccTable from 'Components/table/rcc-table'
import RccButton from 'Components/controls/rcc-button'

export default {
  components: { RccTable, RccButton },

  mixins: [Page, TableList, ProcessesApi],

  computed: {
    headerData() {
      return headerData
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getProcesses({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(({ pages, items }) => {
          if (!pages) {
            this.resetTableData()
            return
          }

          this.pagesCount = pages

          this.rows = items.map(item => {
            return {
              ...item, type: this.$constants.bookingType[item.type],
              starting: this.getSchedule(item)
            }
          })
        })
        .finally(() => { this.isDataLoading = false })
    },

    getSchedule({ schedule_type, schedule_days, round_time: [from, to] }) {
      let schedule = ''
      const sortedDays = [...schedule_days].sort((a, b) => a - b)

      if (schedule_type === 'WEEK') {
        schedule = this.getWeekSchedule(sortedDays)
      } else {
        schedule = this.getMothSchedule(sortedDays)
      }

      return schedule + ` с ${from && from.slice(0, 5)} до ${to && to.slice(0, 5)}`
    },

    getWeekSchedule(days) {
      return days.map(day => {
        return this.$constants.weekDays[day] && this.$constants.weekDays[day].toLowerCase()
      }).join(', ')
    },

    getMothSchedule(days) {
      let schedule = []
      let range = 0
      let from = 0
      let lastDay = 0

      days.forEach((day, index) => {

        if(day === lastDay + 1 && index !== days.length - 1) {
          lastDay = day
          range += 1
          from = range > 1 ? from : day
          return
        } else if (index === days.length - 1) {
          lastDay = day
          range += 1
        }

        if (range === 1) {
          schedule.push(from || day)
        } else if (range === 2) {
          schedule.push(from, lastDay)
        } else if (range > 0) {
          schedule.push(`${from}-${lastDay}`)
        }

        from = day
        range = 1
        lastDay = day
      })

      return schedule.join(', ')
    }
  }
}
</script>
