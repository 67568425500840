export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'process/edit/'
  },
  {
    text: 'Название процесса',
    value: 'name'
  },
  {
    text: 'Тип процесса',
    value: 'type'
  },
  {
    text: 'Количество поставщиков',
    value: 'suppliers_count',
    width:' 300px'
  },
  {
    text: 'Запуск',
    value: 'starting',
    width:' 300px'
  }
]
